import st from './banner.module.css'
function Banner(props) {
    const { website } = props;
    return (
        <div>
            <img src={`${process.env.REACT_APP_SERVER_PUBLIC}${website.homeBackground}`} className={`object-fit-cover  ${st.banner}`} alt="" />
        </div>
    );
}

export default Banner;