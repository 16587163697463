import { useEffect, useState } from 'react';
import st from './menuDisplay.module.css'
import Tooltip from '@mui/material/Tooltip'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getMenusByEstablishment } from '../../../../services/menuApi';
import Menu from '../../../modals/menu/menu';

function MenuDisplay(props) {
    const {establishment} = props;
    const[menus, setMenus] = useState()
    const[selectedMenu, setSelectedMenu] = useState({})
    const[modalDisplay, setModalDisplay] = useState(false);
    const [activeCat, setActiveCat] = useState()
    const id = establishment ? establishment._id : '';

    useEffect(()=>{
        getMenusByEstablishment(id,(cb)=>{
            if (cb.status === 200) {
                setMenus(cb.data.menus)
            }else console.error(cb);
        })
    },[id]) 
    return ( 
        <div className={`card mb-4 ps-2 pe-2 ${st.card}`}>
         {/* Menu */}
         <div className="row ps-2 pt-3 mb-2">
             <div className="col">
                 <h5>
                     Menu
                 </h5>
             </div>
         </div>
         <div className="row ps-2 mb-3">
            {menus && menus.map((menu,i)=>(
             <div  key={i} className={`col-sm-6 pb-2 ${st.separator}`} style={{ borderRight: i % 2 === 0 ? '1px solid #000' : 'none' }}>
                <Tooltip title='Voir le menu' arrow>
                    <div className={`row ms-2 me-2  ${st.menuName}`} type="button" data-bs-toggle="modal" data-bs-target="#menuModal" onClick={()=>{setModalDisplay(true);setSelectedMenu(menu);setActiveCat(menu.categories[0])}}>
                            <div className={`col-9 `}  >{menu.name}</div>
                            <div className="col-3 text-center" ><VisibilityOutlinedIcon/></div>
                    </div>
                </Tooltip>
             </div>
            ))}
             </div>
             <Menu selectedMenu={selectedMenu} activeCat={activeCat} setActiveCat={setActiveCat} modalDisplay={modalDisplay} setModalDisplay={setModalDisplay} />
         </div>
     );
}

export default MenuDisplay;