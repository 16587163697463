import { useEffect, useRef, useState } from 'react';
import st from './menu.module.css';
import { useParams } from 'react-router-dom';
import { getMenusByEstablishment } from '../../services/menuApi';
import MenuList from '../../components/menuConfig/menuRelated/menuList/menuList';
import CategoryList from '../../components/menuConfig/categoryRelated/categoryList/categoryList';
import ItemsList from '../../components/menuConfig/itemRelated/itemsList/itemsList';
import { getEstablishmentById } from '../../services/establishmentApi';
import AddMenu from '../../components/menuConfig/menuRelated/addMenu/addMenu';

function Menu(props) {
    const { user } = props;
    const id = useParams().id;
    const initialRenderRef = useRef(true);
    const [permitted, setPermitted] = useState(false);


    const [menus, setMenus] = useState([]);
    const [categories, setCategories] = useState([]);

    const [activeMenu, setActiveMenu] = useState(null);
    const [activeCategory, setActiveCategory] = useState(null);
    const [refreshItems, setRefreshItems] = useState(false);


    const [edit, setEdit] = useState(null);


    const [refresh, setRefresh] = useState(false);
    const [loaded, setLoaded] = useState(false);


    //Permission
    useEffect(() => {
        if (initialRenderRef.current) {
            getEstablishmentById(id, (cb) => {
                if (cb.status === 200) {
                    if (user.role === "admin") {
                        setPermitted(true);
                        return;
                    }
                    if (cb.data.establishment.provider._id !== user._id) {
                        window.location.href = "/account/profile";
                        return;
                    }
                    setPermitted(true);
                } else {
                    window.location.href = "/";
                }
            })
        }
    }, [user, id])

    useEffect(() => {
        
        if (permitted) {
            console.log("used");
            getMenusByEstablishment(id, (cb) => {
                
                if (cb.status === 200) {
                    setMenus(cb.data.menus);
                    if (cb.data.menus && cb.data.menus.length === 1) {
                        setActiveMenu(cb.data.menus[0]);
                    }
                    setLoaded(true);
                    if (initialRenderRef.current && cb.data.menus.length > 0) {
                        setActiveMenu(cb.data.menus[0]);
                        if (cb.data.menus[0].categories.length > 0) {
                            setActiveCategory(cb.data.menus[0].categories[0]);
                        }
                        initialRenderRef.current = false;
                    }
                } else {
                    if (cb.response) {
                        cb.response.status === 400 && console.error("Couldn't find the establishment!");
                        cb.response.status === 404 && console.error("No menu found!");
                        cb.response.status === 500 && console.error("Internal Server error!");
                    }
                    setMenus([]);
                }
            });
        }
    }, [id, initialRenderRef, permitted, refresh]);

    useEffect(() => {
        if (activeMenu && activeMenu.categories && activeMenu.categories.length > 0) {
            setActiveCategory(activeMenu.categories[0]);
        } else {
            setActiveCategory(null);
        }
    }, [activeMenu])

    return (
        <div className={user.role === "provider" ? "clientPage" : "adminPage"} >
            <AddMenu
                setRefresh={setRefresh} />

            {loaded ? (
                menus.length > 0 ?
                    (
                        <div className={`${st.container}`}>
                            <div className="row mt-xxl-2">
                                {/* Menu related */}
                                <div className={`col-lg-3`}>
                                    <MenuList
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        menus={menus}
                                        setMenus={setMenus}
                                        activeMenu={activeMenu}
                                        setActiveMenu={setActiveMenu}
                                        edit={edit}
                                        setEdit={setEdit}
                                    />
                                </div>
                                {/* Category related */}
                                <div className={`col-lg-4 ${st.middleCol}`}>
                                    <CategoryList
                                        setRefresh={setRefresh}
                                        menus={menus}
                                        setMenus={setMenus}
                                        activeMenu={activeMenu}
                                        activeCategory={activeCategory}
                                        setActiveCategory={setActiveCategory}
                                        categories={categories}
                                        setCategories={setCategories}
                                        edit={edit}
                                        setEdit={setEdit}
                                        setRefreshItems={setRefreshItems}
                                    />
                                </div>
                                {/* Item related */}
                                <div className={`col-lg-5`} >
                                    <ItemsList
                                        menus={menus}
                                        setMenus={setMenus}
                                        activeMenu={activeMenu}
                                        activeCategory={activeCategory}
                                        setActiveCategory={setActiveCategory}
                                        setEdit={setEdit}
                                        categories={categories}
                                        setCategories={setCategories}
                                        refreshItems={refreshItems} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container mt-5">
                            <div className="row text-center">
                                <div className="col">
                                    <span>Vous n'avez pas encore créer un menu</span>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col px-0">
                                    <button data-bs-toggle="modal" data-bs-target={"#addMenuModal"} className={`${st.addMenu} mt-3`} >Ajouter un menu</button>
                                </div>
                            </div>
                        </div>
                    )

            ) : (
                <div className='d-flex justify-content-center mt-5 p-5'>
                    <span className={`mt-5 ${st.loader}`}></span>
                </div>
            )}

        </div>
    );
}

export default Menu;
