import st from './reviews.module.css'

function Reviews() {
    return (
     <div>
        <div className={`card mb-4 ps-2 pe-2 ${st.card}`}>
            <div className="row ps-2 pt-3 mb-2">
                <div className="col">
                    Avis
                </div>
            </div>
            <div className="row text-center">
                <div className="col-4 pb-3">
                    <div className="row">
                        <div className="col">
                           <img src="/images/waiter.png" className={st.img} alt="" /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className={st.text} >Service</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className={st.rating} >0</span>
                        </div>
                    </div>
                </div>
                <div className="col-4 pb-3">
                    <div className="row">
                        <div className="col">
                           <img src="/images/restaurant.png" className={st.img} alt="" /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className={st.text} >Ambiance</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className={st.rating} >0</span>
                        </div>
                    </div>
                </div>
                <div className="col-4 pb-3">
                    <div className="row">
                        <div className="col">
                           <img src="/images/cuisine.png" className={st.img} alt="" /> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className={st.text} >Cuisine</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <span className={st.rating} >0</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    );
}

export default Reviews;