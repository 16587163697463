import AppNavbar from '../../components/navigation/app-navigation/app-navbar';
import Footer from '../../components/footer/footer';
import st from "./contact.module.css";
import { useEffect, useState } from 'react';
import { submitContact } from '../../services/contact';
import io from "socket.io-client";

function Contact(props) {
    const { user, website } = props;
    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";

    const initialValues = {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        message: ""
    }
    const [formValues, setFormValues] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [err, setErr] = useState(false);

    const [socket, setSocket] = useState(null);
    useEffect(() => {
        socketRefresh();
    }, []);

    const socketRefresh = () => {
        const newSocket = io.connect(process.env.REACT_APP_SOCKET_URL);
        setSocket(newSocket);
        return () => {
            newSocket.disconnect();
        };
    }

    const handleChange = (event) => {
        setErr(false);
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const send = (e) => {
        e.preventDefault();
        let fv = formValues;
        if (!fv.lastname || !fv.firstname || !fv.message) {
            setErr("req");
            return;
        }
        if (!fv.phone && !fv.email) {
            setErr("mail-phone");
            return;
        }
        setLoading(true);
        submitContact(formValues, (cb) => {
            if (cb.status === 201) {
                socket.emit('sendMessage', { message: cb.data.message });
                setTimeout(() => {
                    setLoading(false);
                }, 1500);
                setFormValues(initialValues);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
            } else {
                setTimeout(() => {
                    setLoading(false);
                }, 1500);
                setErr("server");
            }
        })
    }

    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div className={`${isProvider && "clientPage"} ${!isAdmin && !isProvider && st.page}`}>
                <div className={`${st.body}`}>
                    <div style={{ backgroundColor: "#2A2A2A" }} className="position-relative">
                        <img src={`${process.env.REACT_APP_SERVER_PUBLIC}${website.contactBackground}`} alt=""
                            className={`${st.pageTitleImage} object-fit-cover`} />
                        <div className={`${st.pageTitleBar} container-lg position-relative`}>
                            <span className={`${st.pageTitle}`}>Contanctez-nous</span>
                        </div>
                    </div>
                    <div className="container-sm my-5 p-4">
                        <div className="row mb-5">
                            <div className="col-lg-6 pt-0 pt-lg-5">
                                <form onSubmit={send} >
                                    <legend> Envoyez-nous un message </legend>
                                    <div className="row pt-4">
                                        <div className="form-group col-sm-6">
                                            <label className="form-label">Nom<span className='txtDanger'>*</span></label>
                                            <input type="text" name='lastname' value={formValues.lastname}
                                                className="form-control" onChange={handleChange} />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label className="form-label">Prénom<span className='txtDanger'>*</span></label>
                                            <input type="text" name='firstname' value={formValues.firstname}
                                                className="form-control" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="form-group pe-sm-0 col-sm-5">
                                            <label className="form-label">Adresse email</label>
                                            <input type="text" name='email' value={formValues.email}
                                                className="form-control" onChange={handleChange} />
                                        </div>
                                        <div className='col-sm-2 text-center pt-3 pb-2 pt-sm-4 mt-sm-3'>
                                            <span className=''>Ou</span>
                                        </div>
                                        <div className="form-group ps-sm-0 col-sm-5">
                                            <label className="form-label">Numéro de téléphone</label>
                                            <input type="number" name='phone' value={formValues.phone}
                                                className="form-control" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="form-group">
                                            <label className="form-label">Message<span className='txtDanger'>*</span></label>
                                            <textarea rows={3} type="text" name='message' value={formValues.message}
                                                className="form-control" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col mt-2 mb-2 d-flex justify-content-center">
                                            {loading && <div className={`${st.loader}`}></div>}
                                            {err === "server" && !loading && <span className='txtDanger'>Un problem est survenu, réessayer plus tard</span>}
                                            {err === "req" && !loading && <span className='txtDanger'>Verifier les champs obligatoires !</span>}
                                            {err === "mail-phone" && !loading && <span className='txtDanger'>Vous devez au moins saisir un numéro de téléphone ou une adresse email !</span>}
                                            {success && !loading && <span className='txtSuccess'>Votre message a été envoyé avec succès</span>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mt-1 text-center text-sm-end">
                                            <button type='submit' className={`${st.send}`}>Envoyer</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6 mb-5 d-flex justify-content-center">
                                <div className={`${st.iFrameContainer}`}>
                                    <iframe title='maps' className={`${st.iFrame}`}
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13249.238938926022!2d10.860976!3d33.881676!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13aaa5a13b3c6bad%3A0xa9d1823ceeb35498!2sSTE%20ROAM%20MAZE!5e0!3m2!1sen!2sus!4v1715619170643!5m2!1sen!2sus"
                                        allowFullScreen="" referrerPolicy="no-referrer-when-downgrade">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer website={website} />
            </div>
        </>
    );
}

export default Contact;
