import { useEffect, useState } from 'react';
import st from './menu.module.css'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Tooltip from '@mui/material/Tooltip';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';

function Menu(props) {
  const { selectedMenu, modalDisplay, setModalDisplay, activeCat, setActiveCat } = props;
  const [hide, setHide] = useState(false);
  const [index, setIndex] = useState(0);
  const [search] = useState('')
  const [categories, setCategories] = useState('')
  const hideCategories = () => {
    setHide(!hide);
  }

  useEffect(() => {
    const hideCategories = () => {
      if (window.innerWidth <= 768) {
        setHide(true);
      } else {
        setHide(false);
      }
    };
    hideCategories();
    window.addEventListener('resize', hideCategories);
    return () => {
      window.removeEventListener('resize', hideCategories);
    };
  }, []);

  const carouselNav = (nav)=>{
    if (nav === 'prev') {
      if (index > 0) {
        setIndex(index-1)
      }else{
        setIndex( selectedMenu.categories.length -1);
      }
    }
    if (nav === 'next') {
      if (index < selectedMenu.categories.length -1) {
        setIndex(index+1)
      }else{
        setIndex(0)
      }
    }
  }
  useEffect(()=>{
    if(search === ''){
      setCategories(selectedMenu.categories && selectedMenu.categories)
      setActiveCat(selectedMenu.categories && selectedMenu.categories[index])
    }else {
      const searching = selectedMenu.categories.filter(
        cat =>cat.name.toLowerCase().includes(search.toLowerCase()))
      setCategories(searching)
      setActiveCat(searching.length > 0 ? searching[0] : null)
    }
      
  },[index,setActiveCat,selectedMenu.categories,search])

  
  return (
    <div className="modal" id="menuModal">
      <div className={`modal-dialog modal-fullscreen ${modalDisplay ? st.zoomIn : ''}`} >
        <div className={`modal-content`} >
          <div className="row relative ps-2 pt-2">
            <div className="col">
              <button type="button" className={`bi bi-x-lg ${st.closeBtn}`} data-bs-dismiss="modal" onClick={() => setModalDisplay(false)}></button>
              <Tooltip title='Catégories' arrow>
                <span onClick={hideCategories} className=''>
                  <MenuOutlinedIcon className={`${st.icon}`} />
                </span>
              </Tooltip>
              <h4 className={st.title}>
                {selectedMenu.name}
              </h4>
            </div>
          </div>
          <div className={`text-center`} >
            <div className={`container-fluid ${hide && st.containerCollapse} ${st.container}`} >
              <div className={`row pe-1 ${hide ? st.hideCat : st.showCat}`} >
                <div className="col pe-1">
                  {/* <div className={`${st.inputGroup}`}>
                    <input autoComplete="off" type="text" placeholder="Rechercher une catégorie" className={`border-0 bg-light ${st.formControl}`} value={search} onChange={(e)=>{setSearch(e.target.value)}} />
                    <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                  </div> */}
                  <div className='text-start ps-5 pt-2'>
                  <RestaurantMenuIcon className={`${st.caticon}`}/>
                  <h5>Catégories</h5>
                  </div>
                  <div className={`${st.categories} `} >
                    {categories && categories.length > 0 && categories.map((category, i) => (
                      <div
                        className={`${st.cat} ${activeCat && (activeCat._id === category._id) && st.activeCat}`}
                        key={i}
                        onClick={() => {console.log(category);setActiveCat(category);setIndex(i);}} >
                        {category.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={`row`}>
                <div className={`col p-0 m-0`} >
                  <div id="menuCarousel" className="carousel carousel-dark slide" data-bs-touch="true" >
                    <div className={`carousel-inner`} >
                      {categories && categories.length > 0 && categories.map((category, i) => (
                        <div key={i} className={`carousel-item ${index === i ? 'active' : ''} px-2`}>
                          <div className="row mb-1">
                            <div className="col">
                              <h4>{category.name}</h4>
                            </div>
                          </div>
                          <div className={`pe-2 ${hide && st.carouselCollapse}  ${st.carousel}`}>
                            <div className={`row mt-1 px-1 ms-0 pe-2`} >
                              {category.items && category.items.map((item, j) => (
                                <div key={j} className={`col-lg-6 py-2`}>
                                  <div className={`p-2 ${st.ItemCard}`}>
                                    <div className={`row`}>
                                      <div className={`col-3 pt-2`}>
                                        <div>
                                          <img src={item.photo ? `${process.env.REACT_APP_SERVER_PUBLIC}${item.photo}` : "/images/dish.png"} className={`${st.ItemPhoto}`} alt={item.name} />
                                        </div>
                                      </div>
                                      <div className={`col-9 ${st.cardcontent}`}>
                                        <div className={`row`}>
                                          <div className="col-7 p-0 text-start">
                                            <h5> {item.name} </h5>
                                          </div>
                                          <div className="col-5 p-0 m-0 text-end">
                                            <h6 className='m-0 mt-1'>{item.price} dt</h6>
                                          </div>
                                        </div>
                                        <div className={`row`}>
                                          <div className={`col text-start small p-0 ${st.description}`} >
                                            <span>{item.description}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div> 
                          </div>
                          
                        </div>
                      ))}
                    </div>
                    <button className={`carousel-control-prev ${st.prev}`} type="button" data-bs-target="#menuCarousel" data-bs-slide="prev" onClick={()=>carouselNav('prev')} >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                    <button className={`carousel-control-next ${st.next}`} type="button" data-bs-target="#menuCarousel" data-bs-slide="next" onClick={()=>carouselNav('next')} >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className={`${st.close}`} data-bs-dismiss="modal" onClick={() => setModalDisplay(false)}>Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
