import st from './home.module.css'
import Footer from '../../components/footer/footer';
import AppNavbar from '../../components/navigation/app-navigation/app-navbar';
import Banner from '../../components/homeComponents/banner/banner';
import { useEffect, useRef, useState } from 'react';
import Establishments from '../../components/homeComponents/establishments/establishments';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Home(props) {
    const { user, website, establishments, menus } = props;
    const isAdmin = user.role === "admin";
    const isProvider = user.role === "provider";
    const isUser = user.role === "user";
    const [activeEstablishments, setActiveEstablishments] = useState([]);

    const mainWindow = useRef(null);
    const [scrollBtn, setScrollBtn] = useState(false);
    const transitionTargetRef = useRef(null);
    const improveTarget = useRef(null);
    const [transition, setTransition] = useState(false);
    const [improveAnim, setImproveAnim] = useState(false);


    useEffect(() => {
        if (establishments) {
            setActiveEstablishments(establishments.filter(e => !e.hidden && !e.locked && !e.deletesAt && new Date(e.expiresAt) > new Date()))
        }
    }, [establishments]);
    ///ANIMATION 

    const observing = (fnc) => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    fnc(true);
                }
            });
        }, options);
        return observer
    }

    useEffect(() => {
        const improveObsv = observing(setImproveAnim);
        if (improveTarget.current) {
            improveObsv.observe(improveTarget.current);
        }
        const trasitionObsv = observing(setTransition);
        if (transitionTargetRef.current) {
            trasitionObsv.observe(transitionTargetRef.current);
        }

    }, []);


    const scrollToTop = () => {
        if (mainWindow.current) {
            mainWindow.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        const myScroll = mainWindow.current;
        const handleScroll = () => {
            if (myScroll.scrollTop >= 300) {
                setScrollBtn(true);
            } else {
                setScrollBtn(false);
            }
        };

        myScroll.addEventListener('scroll', handleScroll);

        return () => {
            myScroll.removeEventListener('scroll', handleScroll);
        };
    }, [])

    return (
        <>
            {!isAdmin && !isProvider && !isUser && <AppNavbar website={website} />}
            <div ref={mainWindow} className={`${isProvider && "clientPage"} ${!isAdmin && !isProvider && st.page}`}
                style={{ scrollBehavior: "smooth" }}>
                <div className={`${st.body}`}>
                    <Banner website={website}/>
                    <div className="container-fluid pt-3">
                        <div className="row">
                            <div className="col">
                                <div className="container-xl p-0 mb-3 mt-2">
                                    <Establishments establishments={activeEstablishments} menus={menus} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col p-0">
                                <div className='container-sm'>
                                    <div className="row">
                                        <div className="col pb-3 mb-3"  >
                                            <h3>
                                                Améliorer & Économiser
                                            </h3>

                                        </div>
                                    </div>
                                    {!improveAnim && <div style={{ height: "300px" }}  ref={improveTarget}>

                                    </div>}
                                    {improveAnim && <div className={`row text-center ${improveAnim ? "fadeInUp" : ""}`} >
                                        <div className="col-lg-4 pb-2">
                                            <QrCode2Icon className={st.icon} />
                                            <h4 className='pt-2'>QR Code</h4>
                                            <div className="text-muted">
                                                Offrez à vos clients un voyage culinaire inoubliable dans votre menu en scannant votre QR Code.
                                            </div>
                                        </div>
                                        <div className="col-lg-4 pb-2">
                                            <BorderColorIcon className={st.icon} />
                                            <h4 className='pt-2'>Modifier</h4>
                                            <div className="text-muted">
                                                Mettez à jour facilement les prix et les plats de votre menu et vos coordonnées en quelques clics.
                                            </div>
                                        </div>
                                        <div className="col-lg-4 pb-2">
                                            <AttachMoneyIcon className={st.icon} />
                                            <h4 className='pt-2'>Économiser</h4>
                                            <div className="text-muted">
                                                Économisez en évitant les coûts d'impression de menus physiques grâce à notre plateforme.
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="row p-3 pt-5 mt-5 p-4">
                                        <div className="col-lg-3 pb-3">
                                            <div className="border-top mt-4"></div>
                                        </div>
                                        <div className="col-lg-9">
                                            <h1>
                                                Brillez dans l'univers de la restauration.
                                            </h1>
                                            <div className={`text-muted fw-light ${st.text}`}>
                                                Avoir un menu en ligne est essentiel dans le contexte actuel où de plus en plus de clients recherchent des informations sur les restaurants en ligne. Cela permet de répondre à leurs attentes et de rester compétitif sur le marché. Un menu en ligne offre une accessibilité 24 heures sur 24, 7 jours sur 7, permettant aux clients de consulter les plats  à tout moment. Il simplifie également la gestion des commandes pour les restaurants, réduisant les erreurs de communication et améliorant l’efficacité du service.                                Enfin, un menu en ligne facilite les mises à jour fréquentes, que ce soit pour les changements de menu, les promotions ou les ajustements en fonction des demandes des clients.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-5 position-relative ${st.col} ${transition ? st.fadein : ''}`} ref={transitionTargetRef}>
                        <div className={`${st.contactUs} ${transition ? st.fadein : ''}`}>
                            <h3 className={`${st.contactUsTxt} ${transition ? "fadeInUp" : ""}`}>Rejoignez-nous</h3>
                            <button onClick={() => { window.location.href = "/contact" }}
                                className={`mt-3 ${st.contactUsBtn} ${transition ? "fadeInUp" : ""}`}>
                                Nous contacter
                            </button>
                        </div>
                        <img src="/images/banner.jpg" className={`object-fit-cover  ${st.banner}`} alt="" />
                    </div>
                    <div className="py-5">
                        <div className="text-center">
                            <h1>Les avantages de {website && website.name}</h1>
                        </div>
                    </div>
                    <div className="pb-4">
                        <div className="col d-flex justify-content-center">
                            <div className={`${st.card} accordion accordion-flush`} id="accordionFlushExample">
                                <div className={`accordion-item ${st.item}`}>
                                    <h6 className="accordion-header">
                                        <button className={`accordion-button collapsed ${st.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            ÉCONOMIE DE COÛTS
                                        </button>
                                    </h6>
                                    <div id="flush-collapseOne" className={`accordion-collapse collapse`} data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Réduisez les dépenses liées à la gestion des menus physiques en optant pour une solution numérique.</div>
                                    </div>
                                </div>
                                <div className={`accordion-item ${st.item}`}>
                                    <h6 className="accordion-header">
                                        <button className={`accordion-button collapsed ${st.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            MISE À JOUR FACILE
                                        </button>
                                    </h6>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Modifiez rapidement les prix, les plats et les promotions en quelques clics, sans attendre l’impression de nouveaux menus.</div>
                                    </div>
                                </div>
                                <div className={`accordion-item ${st.item}`}>
                                    <h6 className="accordion-header">
                                        <button className={`accordion-button collapsed ${st.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            VISIBILITÉ EN LIGNE ACCRUE
                                        </button>
                                    </h6>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Atteignez un large public en offrant un menu en ligne accessible à tout moment, de n’importe où.</div>
                                    </div>
                                </div>
                                <div className={`accordion-item ${st.item}`}>
                                    <h6 className="accordion-header">
                                        <button className={`accordion-button collapsed ${st.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            SERVICE RAPIDE
                                        </button>
                                    </h6>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Une fois que vous avez scanné le QR Code, le menu apparaîtra instantanément sur votre écran. Vous n’avez plus besoin d’attendre qu’un serveur vous apporte un menu physique.</div>
                                    </div>
                                </div>
                                <div className={`accordion-item ${st.item}`}>
                                    <h6 className="accordion-header">
                                        <button className={`accordion-button collapsed ${st.button}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            IMAGE PROFESSIONNELLE
                                        </button>
                                    </h6>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Montrez-vous comme un établissement moderne et technologiquement avancé en proposant un menu en ligne.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <span className={`${st.scrollToTopButton}`}
                    onClick={scrollToTop}>
                    <KeyboardArrowUpIcon className={`${st.Scrollbutton} ${scrollBtn ? "fadeInUpScroll" : 'fadeOut'}`} />
                </span>
                <Footer website={website} />
            </div>
        </>
    );
}

export default Home;
